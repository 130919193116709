import { X } from "@phosphor-icons/react";
import React, { useEffect } from "react";
import { animate, AnimatePresence, motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import { alertDrawer } from "../layout";

const AlertContent = ({
  onCloseAlert,
  alert,
  alertStackNumber,
  isAlertStacking,
}) => {
  let bgColor = "bg-alabaster text-rich_black";
  if (alert.type === "info") bgColor = "bg-pumpkin-50 text-alabaster";

  useEffect(() => {
    let timeout = alert.type == "error" ? 4000 : 2000;

    if (isAlertStacking) timeout = 1500 * (alertStackNumber + 1);

    setTimeout(() => {
      onCloseAlert();
    }, timeout);
  }, []);

  return (
    <motion.div
      id={`alert_stack_${alertStackNumber}`}
      className={`relative break-words p-4 border-0 rounded-[15px] ${bgColor} flex justify-between items-center w-full transition-transform duration-300 ${
        alert.type === "info" ? "max-w-[50vw]" : "max-w-[100vw] lg:max-w-[50vw]"
      } space-x-6`}
      key={`alert-role-${alertStackNumber + 1}`}
      initial={{
        right: isMobile ? 0 : -50,
        opacity: 0,
      }}
      animate={{
        right: isMobile ? 0 : 0,
        opacity: 1,
      }}
      transition={{
        duration: 0.4,
      }}
    >
      {alert.content}
      <a
        className="cursor-pointer"
        onClick={() => onCloseAlert(alertStackNumber)}
      >
        <X
          weight="bold"
          color={alert.type == "info" ? "#F2EFE6" : "#010101"}
          size={22}
        />
      </a>
    </motion.div>
  );
};

export default function Alert() {
  const onCloseAlert = async (selectedAlert = 0) => {
    const alertDom = document.getElementById(`alert_stack_${selectedAlert}`);
    if (!alertDom) return;

    await animate(alertDom, { right: -50, opacity: 0 }, { duration: 0.4 });

    alertDrawer.value = alertDrawer.value.map((alertData, indexAlert) => {
      const result = alertData;
      if (indexAlert === selectedAlert) result.show = false;

      return result;
    });

    if (!alertDrawer.value.filter((alertData) => alertData.show).length) {
      alertDrawer.value = [];
    }
  };

  return (
    <div className="fixed flex flex-col-reverse z-[999] right-1/2 lg:right-16 translate-x-1/2 lg:translate-x-0 top-24 lg:top-32 space-y-3 space-y-reverse w-[calc(100vw-2rem)] md:w-auto">
      <AnimatePresence>
        {alertDrawer.value.map((alert, alertIndex) => {
          if (alert.show) {
            return (
              <AlertContent
                alert={alert}
                isAlertStacking={alertDrawer.value.length > 1}
                onCloseAlert={() => onCloseAlert(alertIndex)}
                alertStackNumber={alertIndex}
                key={alertIndex}
              />
            );
          }
        })}
      </AnimatePresence>
    </div>
  );
}
