const BACKEND_URL = `${process.env.NEXT_PUBLIC_BYTHEN_API_URL}ms/auth/`;

export const challenges = async (data) => {
  const response = await fetch(`${BACKEND_URL}v1/challenges`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (response.status !== 200) return { user: null };

  const _user = await response.json();

  return { user: _user };
};

export const bythenLogin = async (payload) => {
  try {
    const response = await fetch(`${BACKEND_URL}v1/login`, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();

    if (response.status != 200) throw new Error(data.error);

    return { user: data, status: response.status };
  } catch (error) {
    console.log("🚀 ~ bythenLogin ~ error:", error);
    const eror = new Error(error.message);
    // e.message is 'Could not parse input'
    throw eror;
  }
};

export const bythenLogout = async (data) => {
  const response = await fetch(`${BACKEND_URL}v1/logout`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (response.status !== 200) return { user: null };

  const _user = await response.json();

  return { user: _user };
};
