import { WarningCircle } from "@phosphor-icons/react";

export default function AlertErrorContent({ errorMessage }) {
  return (
    <div className="flex items-center space-x-3 max-w-[85%]">
      <span>
        <WarningCircle color="#EA3E00" size={32} />
      </span>
      <span style={{ wordBreak: "break-word" }}>{errorMessage}</span>
    </div>
  );
}
