import React, { useCallback, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import { Modal, ModalContent, ModalBody } from "@nextui-org/react";
import { useAccount, useAccountEffect } from "wagmi";
import { signal } from "@preact/signals-react";

import SignWallet from "./Dialog/SignWallet";
import Alert from "./Alert";
import { bythenLogout } from "@/api/account";
import { isMobile } from "react-device-detect";
import LoaderComponent from "./Loader";
import { useRouter } from "next/router";
import { ReactLenis } from "lenis/react";
import { triggerAfterSignWallet, triggerAfterConnect } from "./Dialog/signal";
import { isWagmiConnected, wagmiConnection } from "@/signal/layout";

export const bythenAccount = signal();

export const showLoader = signal(false);

export const alertDrawer = signal([]);

export const modalDialog = signal({
  show: false,
  body: null,
  backgroundClose: true,
  hideCloseButton: false,
  backdrop: "blur",
  baseClass: "",
  bodyClass: "",
  closeButtonClass: "",
});

const getCookieAuth = () => {
  if (!Cookies.get("BYTHEN_AUTH")) return null;

  try {
    const authCookie = JSON.parse(Cookies.get("BYTHEN_AUTH"));
    return authCookie;
  } catch (e) {
    return null;
  }
};
export default function Layout({ children }) {
  const { address, status } = useAccount();
  const router = useRouter();
  const startLoadingRef = useRef(null);

  useEffect(() => {
    if (status === "reconnecting" || status === "connecting") {
      startLoadingRef.current = true;
    } else if (startLoadingRef.current) {
      wagmiConnection.value = status === "connected" || address;
      startLoadingRef.current = false;
    }
  }, [status, address]);

  const processLogout = useCallback(async () => {
    if (Cookies.get("BYTHEN_AUTH")) {
      Cookies.remove("BYTHEN_AUTH", {
        domain: ".bythen.ai",
        path: "/",
      });
    }
    triggerAfterConnect.value = false;
    wagmiConnection.value = false;

    if (bythenAccount.value) {
      bythenAccount.value = "";

      try {
        const { access_token, account_id, account_type } = bythenAccount.value;
        await bythenLogout({
          access_token,
          account_id,
          account_type,
        });
      } catch (error) {
        console.log("🚀 ~ onDisconnect ~ error:", error);
      } finally {
        modalDialog.value = { ...modalDialog.value, show: false };
      }
    }
  }, []);

  const logoutWithReload = useCallback(async () => {
    await processLogout();
    router.reload();
  }, [processLogout, router]);

  useEffect(() => {
    //Trigger if wagmi connected
    if (isWagmiConnected.value) {
      const cookieAuth = getCookieAuth();

      window.localStorage.removeItem("wagmi.io.metamask.disconnected");

      if (triggerAfterConnect.value) {
        triggerAfterSignWallet.value = true;
      }

      if (cookieAuth && cookieAuth?.wallet_address) {
        bythenAccount.value = { ...cookieAuth, fetch_reward: true };
        return;
      }

      modalDialog.value = {
        show: true,
        body: <SignWallet />,
        backgroundClose: false,
        hideCloseButton: true,
        backdrop: "opaque",
        baseClass:
          "border-alabaster/10 border-2 border-[#303033] rounded-[24px] bg-[#303033] md:max-w-[370px] md:w-full",
        bodyClass: "bg-[#1A1B1F]",
      };
    }
  }, [isWagmiConnected.value]);

  useEffect(() => {
    //Trigger if user change address
    if (isWagmiConnected.value && address) {
      const cookieAuth = getCookieAuth();

      if (!cookieAuth) return;

      if (cookieAuth?.wallet_address && cookieAuth.wallet_address !== address) {
        logoutWithReload();
      }
    }
  }, [isWagmiConnected.value, address, logoutWithReload]);

  useAccountEffect({
    async onDisconnect() {
      processLogout();
    },
  });

  return (
    <ReactLenis root>
      {showLoader.value && <LoaderComponent />}

      {alertDrawer.value.length ? <Alert /> : null}

      {children}

      <Modal
        isOpen={modalDialog.value.show}
        placement={isMobile ? "bottom" : "center"}
        isDismissable={modalDialog.value.backgroundClose}
        onClose={() => {
          modalDialog.value = {
            ...modalDialog.value,
            show: false,
          };
        }}
        scrollBehavior={"inside"}
        backdrop={modalDialog.value.backdrop}
        hideCloseButton={modalDialog.value.hideCloseButton}
        classNames={{
          wrapper: "w-full",
          backdrop: "backdrop-brightness-50",
          base: `overflow-y-visible max-h-[70vh] md:max-h-[80vh] w-full max-w-full max-w-[542px] m-0 md:mx-6 sm:my-0 rounded-b-none md:rounded-b-3xl rounded-t-3xl md:rounded-t-3xl" ${modalDialog.value.baseClass}`,
          body: `px-[28px] py-[84px] pb-[28px] rounded-b-none md:rounded-b-3xl rounded-t-3xl md:rounded-t-3xl bg-pampas justify-center items-center ${modalDialog.value.bodyClass}`,
          closeButton: `hover:bg-white/5 active:bg-white/10 [&>svg]:w-[24px] [&>svg]:h-[24px] p-3 ${modalDialog.value.closeButtonClass}`,
        }}
      >
        <ModalContent>
          <ModalBody>{modalDialog.value.body}</ModalBody>
        </ModalContent>
      </Modal>
    </ReactLenis>
  );
}
