import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import { useAccount, useDisconnect, useSignMessage } from "wagmi";

import { bythenLogin, bythenLogout, challenges } from "@/api/account";

import AlertErrorContent from "../Alert/ErrorContent";
import { alertDrawer, bythenAccount, modalDialog } from "../layout";
import { ENVIRONMENT } from "@/utils/environment";
import { triggerAfterSignWallet } from "./signal";
import { wagmiConnection } from "@/signal/layout";

export default function SignWallet() {
  const { disconnect } = useDisconnect();
  const { address } = useAccount();
  const { signMessage, data: signatureData } = useSignMessage();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [nonce, setNonce] = useState(null);
  const { query } = useRouter();

  useEffect(() => {
    if (signatureData) {
      onLogin();
    }
  }, [signatureData]);

  const setTriggerActionAfterConnect = (status) => {
    triggerAfterSignWallet.value = status;
  };

  const onLogin = async () => {
    try {
      const payload = {
        nonce: nonce,
        wallet_public_key: address,
        signature: signatureData,
      };

      if (query.referral_code) payload.referral_code = query.referral_code;

      const loginResponse = await bythenLogin(payload);

      if (loginResponse && loginResponse.user) {
        Cookies.set(
          "BYTHEN_AUTH",
          JSON.stringify({
            ...loginResponse.user,
            wallet_address: address,
          }),
          { domain: ".bythen.ai", path: "/", expires: 365 * 5 }
        );

        bythenAccount.value = {
          ...loginResponse,
          fetch_reward: true,
          wallet_address: address,
        };
        modalDialog.value = { ...modalDialog.value, show: false };
        setIsAuthenticating(false);
      }
    } catch (error) {
      setTriggerActionAfterConnect(false);
      console.log("🚀 ~ onLogin ~ error:", error);
      setIsAuthenticating(false);
      alertDrawer.value = [
        {
          show: true,
          type: "error",
          content: <AlertErrorContent errorMessage={error.message} />,
        },
      ];
    }
  };

  const getNonce = useCallback(async () => {
    const { user } = await challenges({
      wallet_public_key: address,
    });

    if (user) {
      return user.nonce;
    }

    return "";
  }, [address]);

  const onAuthenticateWallet = useCallback(async () => {
    if (!address) {
      setTriggerActionAfterConnect(false);
    }
    setIsAuthenticating(true);

    try {
      const _nonce = await getNonce(address);
      if (!_nonce) return;
      setNonce(_nonce);

      signMessage(
        {
          message: _nonce,
        },
        {
          onError(e) {
            setIsAuthenticating(false);
            alertDrawer.value = [
              {
                show: true,
                type: "error",
                content: <AlertErrorContent errorMessage={e.message} />,
              },
            ];
            setTriggerActionAfterConnect(false);
          },
        }
      );
    } catch (e) {
      const eror = new Error(e.message);
      setIsAuthenticating(false);
      setTriggerActionAfterConnect(false);
      // e.message is 'Could not parse input'
      throw eror;
    }
  }, [address]);

  const disconnectWallet = async () => {
    disconnect(); // wallet web3 disconnect

    Cookies.remove("BYTHEN_AUTH", {
      domain: ".bythen.ai",
      path: "/",
    });

    wagmiConnection.value = false;
    bythenAccount.value = "";
    setTriggerActionAfterConnect(false);
    try {
      const { access_token, account_id, account_type } = bythenAccount.value;
      const logout = await bythenLogout({
        access_token,
        account_id,
        account_type,
      });
    } catch (error) {
      console.log("🚀 ~ onDisconnect ~ error:", error);
    } finally {
      modalDialog.value = { ...modalDialog.value, show: false };
    }
  };

  return (
    <>
      <>
        <img
          src={`${ENVIRONMENT.ASSETS_URL}/quests/quest-unlocked.svg`}
          width="112"
          height="112"
          className="absolute -top-16 left-1/2 -translate-x-1/2"
        />
        <img
          src={`${ENVIRONMENT.ASSETS_URL}/quests/sign-title.svg`}
          width="334"
          alt="compute-quest-text"
          className="mb-[18px] w-auto max-w-[334px] px-[18px]"
        />
        <p className="mb-20 text-base text-center tracking-[0.18px] text-alabaster/60">
          bythen uses this signature to verify that you&apos;re the owner of
          this Ethereum address.
        </p>
        <button
          onClick={() => onAuthenticateWallet()}
          disabled={isAuthenticating}
          className="w-full bg-white border-0 flex justify-center items-center font-medium text-rich_black rounded-[56px] py-3.5 px-14 hover:bg-[#e2e2e2]"
        >
          {isAuthenticating ? (
            <>
              <svg
                className="animate-spin h-5 w-5 text-current mr-2 [&>circle]:stroke-black/50 [&>path]:fill-black/50"
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="#010101"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  fill="#010101"
                />
              </svg>
              <span className="text-black/50">Sign Message in Wallet</span>
            </>
          ) : (
            "Continue"
          )}
        </button>
        <button
          onClick={() => disconnectWallet()}
          className="w-full bg-snow/10 border-0 font-medium text-snow/90 rounded-[56px] py-3.5 px-14 hover:bg-snow/20"
        >
          Disconnect
        </button>
      </>
    </>
  );
}
